import { Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import SEO from '../components/seo';
// @ts-ignore
import Street from '../images/404/emty-street.svg';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Flex
      flexDir="column"
      w="100%"
      alignItems="center"
      justifyContent="center"
      mt={16}
    >
      <Flex w="50%" minH="20rem" alignItems="center" justifyContent="center">
        <Street />
      </Flex>
      <Heading mt={8} color="gray.800">
        Oops, there's nothing here.
      </Heading>
      <Text mt={4} fontSize="lg">
        This page doesn't exist.
      </Text>
    </Flex>
  </>
);

export default NotFoundPage;
